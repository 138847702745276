import React from 'react';
import useWhyDidYouUpdate from './useWhyDidYouUpdate';
function withReactMemo(name, WrappedComponent, checkWhyDidYouUpdate = false) {
  return React.memo((props) => {
    if (checkWhyDidYouUpdate) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useWhyDidYouUpdate(name, props);
    }
    return <WrappedComponent {...props} />;
  });
}
export default withReactMemo;
