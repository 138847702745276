import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { doLogin as AuthLogin } from './redux/AuthActions';
import { doLogin as SessionLogin } from './redux/SessionActions';
import { callStatus } from './redux/constants';

import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Alert, Button, Dropdown, Form, Input, Menu, Space, Spin } from 'antd';

import { Icon } from '@mdi/react';
import { isEmpty } from 'lodash';
import { doChangeLoginLanguage } from './redux/TranslationLoginAction';
import { mdiArrowRightBold, mdiEarth } from './utils/mdi';

const Main = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const StyledIframe = styled.iframe`
  width: 100%;
  border-width: 0px;
  height: 100%;
`;
const Container = styled.div`
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`;
const ImageCointainer = styled.div`
  margin-bottom: 20px;
`;
const CustomAlert = styled(Alert)`
  margin-top: 20px;
  width: 100%;
`;
const CustomButton = styled(Button)`
  width: 100%;
  span {
    color: white;
  }
`;

const LanguageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: lightgrey;
  cursor: pointer;
  &:hover {
    color: unset;
  }
`;

const MenuItemWrapper = styled(Menu.Item)`
  &:hover {
    background: rgba(169, 169, 169, 0.5) !important;
  }
`;

const Login = ({ modalOpen }) => {
  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);
  const session = useSelector(({ session }) => session);
  const context = useSelector(({ context }) => context);
  const translations = useSelector(({ translations }) => translations);
  const translationsLogin = useSelector(
    ({ translationsLogin }) => translationsLogin
  );

  const [form] = Form.useForm();

  const handleSubmit = async (e) => {
    e.preventDefault();

    form.validateFields().then((values) => {
      if (
        session.status === callStatus.EXPIRED ||
        session.status === callStatus.FAILED
      ) {
        dispatch(
          SessionLogin(
            { username: values.user, password: values.password },
            modalOpen
          )
        );
      } else {
        dispatch(
          AuthLogin(
            { username: values.user, password: values.password },
            modalOpen
          )
        );
      }
    });
  };
  let userInput = useRef(null);
  let passwordInput = useRef(null);

  useEffect(() => {
    if (userInput.current) {
      userInput.current.input.focus();
    }
    // eslint-disable-next-line
  }, [userInput]);

  useEffect(() => {
    if (session.status === callStatus.EXPIRED) {
      if (userInput.current) {
        userInput.current.input.focus();
      }

      if (
        passwordInput.current &&
        (session.data['new_user'] || session.data['user'])
      ) {
        passwordInput.current.input.focus();
      }
    }
    // eslint-disable-next-line
  }, [session]);

  return (
    <>
      <Main>
        {auth.status === callStatus.IN_PROCESS ||
        session.status === callStatus.IN_PROCESS ? (
          <Spin />
        ) : (
          <>
            <Container>
              <ImageCointainer>
                <img src={`./smartlis.png`} alt="" />
              </ImageCointainer>
              <Form
                form={form}
                onFinish={handleSubmit}
                style={{ width: '100%' }}
                initialValues={{
                  user:
                    session.status === callStatus.EXPIRED &&
                    (session.data['new_user'] || session.data['user'])
                      ? session.data['new_user'] || session.data['user']
                      : undefined,
                }}
              >
                <Form.Item
                  name="user"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your user!',
                    },
                  ]}
                  hasFeedback
                  style={{ marginBottom: '15px', height: '40px' }}
                >
                  <Input
                    prefix={<UserOutlined className="input-icon" />}
                    placeholder={translations.data.login.username}
                    onPressEnter={handleSubmit}
                    ref={userInput}
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                  ]}
                  hasFeedback
                  style={{ marginBottom: '15px', height: '40px' }}
                  ref={passwordInput}
                >
                  <Input.Password
                    prefix={<LockOutlined className="input-icon" />}
                    type="password"
                    placeholder={translations.data.login.password}
                    onPressEnter={handleSubmit}
                    ref={passwordInput}
                  />
                </Form.Item>
              </Form>

              <CustomButton
                type="primary"
                onClick={handleSubmit}
                style={{ marginBottom: '15px' }}
              >
                {translations.data.login.login}
              </CustomButton>
              {context.configVars &&
                Boolean(context.configVars.SL_MAIN_PASSWORD_RESET_ENABLE) && (
                  <a
                    href={`${context.configVars.SL_MAIN_PASSWORD_RESET_URL}`}
                    style={{ fontWeight: 'bold', color: '#3c99f6' }}
                  >
                    {translations.data.login.forgot_password}
                  </a>
                )}
              {(auth.status === callStatus.FAILED ||
                session.status === callStatus.FAILED) && (
                <CustomAlert
                  message={
                    auth.status === callStatus.FAILED
                      ? auth.error
                      : session.error
                  }
                  type="error"
                  showIcon
                />
              )}

              {session.status === callStatus.EXPIRED && session.error && (
                <CustomAlert message={session.error} type="error" showIcon />
              )}
            </Container>
            {!modalOpen.current &&
              context.configVars &&
              context.configVars.SL_MAIN_CUSTOM_INFO_PANEL &&
              context.configVars.SL_MAIN_CUSTOM_INFO_PANEL !== '' && (
                <StyledIframe
                  src={context.configVars.SL_MAIN_CUSTOM_INFO_PANEL}
                  style={
                    context.configVars.SL_MAIN_CUSTOM_INFO_PANEL_HEIGHT &&
                    context.configVars.SL_MAIN_CUSTOM_INFO_PANEL_HEIGHT !== ''
                      ? {
                          height:
                            context.configVars.SL_MAIN_CUSTOM_INFO_PANEL_HEIGHT,
                        }
                      : { flex: 1 }
                  }
                />
              )}
          </>
        )}
        {translationsLogin.status === callStatus.SUCCESS && (
          <div
            style={{
              position: 'absolute',
              top: 20,
              right: 20,
            }}
          >
            <LanguageWrapper>
              <Icon
                style={{ marginRight: '4px' }}
                path={mdiEarth}
                title=""
                size={'20px'}
              />
              <Dropdown
                placement="bottomLeft"
                overlay={
                  <Menu>
                    {translationsLogin.languages.map((lang) => {
                      return (
                        <MenuItemWrapper
                          key={lang.id}
                          disabled={
                            isEmpty(translationsLogin.data[lang.code]) ||
                            translationsLogin.defaultLanguage === lang.code
                          }
                          style={{
                            background:
                              translationsLogin.defaultLanguage === lang.code
                                ? '#00afff'
                                : 'unset',
                            color:
                              translationsLogin.defaultLanguage === lang.code &&
                              'white',
                          }}
                        >
                          <a
                            href="#"
                            onClick={(event) => {
                              event.preventDefault();
                              dispatch(doChangeLoginLanguage(lang.code));
                            }}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            {translationsLogin.defaultLanguage ===
                              lang.code && (
                              <Icon
                                style={{ marginRight: '4px' }}
                                path={mdiArrowRightBold}
                                title=""
                                size={'18px'}
                              />
                            )}
                            {lang.name}
                          </a>
                        </MenuItemWrapper>
                      );
                    })}
                  </Menu>
                }
              >
                <Space>Languages</Space>
              </Dropdown>
            </LanguageWrapper>
          </div>
        )}
      </Main>
    </>
  );
};

export default Login;
