import { callStatus } from './constants';
import { IFRAME, MAIN, MODULE } from './types/MainActionTypes';

const initialState = {
  data: {},
  status: callStatus.PENDING,
  error: null,
  iframes: [],
  modules: [],
  activeItem: {},
};

const AuthReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case MAIN.IDLE:
      newState = {
        ...state,
        status: callStatus.PENDING,
      };
      return newState;

    case MAIN.IN_PROGRESS:
      newState = {
        ...state,
        status: callStatus.IN_PROCESS,
      };
      return newState;

    case MAIN.RESOLVED:
      newState = {
        ...state,
        iframes: [],
        status: callStatus.SUCCESS,
        data: action.payload,
      };
      return newState;

    case MAIN.REJECTED:
      newState = {
        ...state,
        status: callStatus.FAILED,
        error: action.payload,
      };
      return newState;

    case IFRAME.SET_INIT: {
      // Check if iframe already exists
      const iframe = state.iframes.find(
        (value) => value.id === action.payload.id
      );

      newState = {
        ...state,
        activeItem: { id: action.payload.id, code: action.payload.code },
        iframes: [
          {
            ...action.payload,
            showPanel: true,
            refresh: iframe ? iframe.refresh + 1 : 0,
          },
        ],
      };
      return newState;
    }

    case IFRAME.SHOW: {
      const iframes = state.iframes.map((iframe) => {
        if (iframe.id === action.payload.id) {
          return { ...iframe, showPanel: true };
        } else {
          return { ...iframe, showPanel: false };
        }
      });

      if (state.iframes.find((value) => value.id === action.payload.id)) {
        newState = {
          ...state,
          activeItem: action.payload.id.startsWith('CUSTOM_QUERY')
            ? state.activeItem
            : { id: action.payload.id, code: action.payload.code },
          iframes: [...iframes],
        };
      } else {
        newState = {
          ...state,
          activeItem: action.payload.id.startsWith('CUSTOM_QUERY')
            ? state.activeItem
            : { id: action.payload.id, code: action.payload.code },
          iframes: [
            ...iframes,
            { ...action.payload, showPanel: true, refresh: 0 },
          ],
        };
      }
      return newState;
    }

    case IFRAME.REFRESH: {
      if (!state.iframes.find((value) => value.showPanel)) {
        return state;
      }

      const currentIframe = state.iframes.find((iframe) => iframe.showPanel);
      const iframes = state.iframes.map((iframe) => {
        if (iframe.id === currentIframe.id) {
          return { ...iframe, refresh: iframe.refresh + 1 };
        } else {
          return iframe;
        }
      });

      newState = {
        ...state,
        iframes: [...iframes],
      };
      return newState;
    }

    case IFRAME.DUPLICATE: {
      if (!state.iframes.find((value) => value.showPanel)) {
        return state;
      }

      const currentModule = state.iframes.find((value) => value.showPanel);
      const moduleIdx = state.modules.findIndex(
        (item) => item.id === currentModule.id
      );
      const newModules = [...state.modules];
      const newCurrentModule = {
        ...currentModule,
        id: `${currentModule.id * 100}`,
        name: currentModule.name + ' 2',
      };
      newModules.splice(moduleIdx + 1, 0, newCurrentModule);

      const iframes = state.iframes.map((iframe) => {
        return { ...iframe, showPanel: false };
      });

      newState = {
        ...state,
        activeItem: { id: newCurrentModule.id, code: newCurrentModule.code },
        iframes: [
          ...iframes,
          { ...newCurrentModule, showPanel: true, refresh: 0 },
        ],
        modules: [...newModules],
      };
      return newState;
    }

    case MODULE.SET: {
      newState = {
        ...state,
        modules: [...action.payload],
      };
      return newState;
    }

    default:
      return state;
  }
};

export default AuthReducer;
