export const LOGIN = {
  IDLE: 'sl-main/auth/LOGIN/idle',
  IN_PROGRESS: 'sl-main/auth/LOGIN/pending',
  REJECTED: 'sl-main/auth/LOGIN/rejected',
  RESOLVED: 'sl-main/auth/LOGIN/resolved',
  FAILED_GET: 'sl-main/auth/LOGIN/failed_get',
  EXPIRED: 'sl-main/auth/LOGIN/expired',
  REFRESH: 'sl-main/auth/LOGIN/refresh',
};

export const CHANGE_PASSWORD = {
  IDLE: 'sl-main/auth/CHANGE_PASSWORD/idle',
  RESOLVED: 'sl-main/auth/CHANGE_PASSWORD/resolved',
  REJECTED: 'sl-main/auth/CHANGE_PASSWORD/rejected',
};
