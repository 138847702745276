import { Alert, Layout, Modal, Spin, notification } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { createGlobalStyle } from 'styled-components';
import colors from '../constants/colors';
import { doLogout } from '../redux/AuthActions';
import { doExpired } from '../redux/SessionActions';
import { callStatus } from '../redux/constants/index';
import Iframe from './Iframe';

import { withRouter } from 'react-router-dom';
import Login from '../Login';
import {
  doAlert,
  doChangeWorkstation,
  doNotification,
} from '../redux/ContextAction';
import {
  doLoadDesign,
  setInitIframe,
  setModules,
  showIframe,
} from '../redux/MainActions';
import IdleTimer from '../utils/IdleTimer';
import { importUseCli } from '../utils/dynamic-imports-sl-cli';
import More from './More';
import Nav from './Nav';
import TopBanner from './TopBanner';
const { Header, Content } = Layout;

const useCli = importUseCli();

const Container = styled.div`
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HeaderIcons = styled.div`
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  line-height: 36px;
  width: 50px;
`;
const HeaderMore = styled.div`
  color: white;
  height: 36px;
  line-height: 36px;
  text-align: right;
  display: flex;
  align-items: center;
  z-index: 7;
`;

const HeaderContainer = styled.div`
  display: flex;
`;

const HeaderMenu = styled.div`
  flex: 1;
`;

const Svg = styled.svg`
  cursor: pointer;
  width: 19px;
  height: 19px;
`;

const LayoutStyle = createGlobalStyle`

.ant-layout-header{
  padding: 0;
  height: 36px;
  line-height: 36px;
  position: relative;
  background: #1e88e5;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 4px 0px, rgba(0, 0, 0, 0.24) 0px 4px 4px 0px;

  z-index:2;
 
}

.ant-layout-content{
  background:#f0f2f5;
  padding: 0;
  margin: 0 !important;
  position: relative;
  z-index:1;
}

.ant-layout-footer{
  background:#f0f2f5;
  padding: 0;
  height: auto;
}

.ant-dropdown{
}

 .ant-dropdown-menu{
  margin-top:0px;
   padding:5px 0px;
 }

.ant-dropdown-menu-item{
  color:white;
  border-left: 3px solid transparent;
  margin-top:3px;
}

.ant-spin-nested-loading{
  height:100%;
  width:100%;
}

.ant-spin-container{
  height:100%;
}

.ant-badge-dot{
  width:4px;
  height:4px;
  min-width:4px;
}
`;

const Main = ({ location, forceLogoutRef, modalOpen }) => {
  const dispatch = useDispatch();
  const main = useSelector(({ main }) => main);
  const { iframes, modules, activeItem } = main;
  const session = useSelector(({ session }) => session);
  const auth = useSelector(({ auth }) => auth);
  const context = useSelector(({ context }) => context);
  const { color } = context;

  const timeoutMins = useSelector(
    ({
      main: {
        data: { logout_timeout_mins },
      },
    }) => logout_timeout_mins
  );

  const cli = useCli();

  const openNotification = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
    });
  };

  useEffect(() => {
    context.notification?.forEach((notification) =>
      openNotification(
        notification.type,
        notification.title,
        notification.value
      )
    );

    if (context.notification?.length > 0) {
      dispatch(doNotification());
    }
  }, [context.notification, dispatch]);

  useEffect(() => {
    const openAlert = (alert) => {
      Modal[alert.type]({
        title: alert.title,
        content: alert.value,
      });

      // Reset alert
      dispatch(doAlert());
    };

    if (context.alert) {
      openAlert(context.alert);
    }
  }, [context.alert, dispatch]);

  useEffect(() => {
    if (auth.status === callStatus.SUCCESS) {
      let searchParams = new URLSearchParams(location.search);
      let jsonParams = JSON.parse(searchParams.get('post'));

      let url = null;

      if (jsonParams !== null) {
        if (jsonParams.url) {
          url = jsonParams.url;
        }

        if (jsonParams.run) {
          cli.execCommand(jsonParams.run.join('\n'));
        }

        if (jsonParams.workstation) {
          dispatch(doChangeWorkstation(jsonParams.workstation));
        }
      }

      doLoadDesign(url, dispatch);
    }
    // eslint-disable-next-line
  }, [location, auth.status]);

  useEffect(() => {
    if (session.status === callStatus.REFRESH) {
      const init = getInit();

      if (init) {
        dispatch(setInitIframe(init));
      }

      dispatch(setModules(modules));
    }
    // eslint-disable-next-line
  }, [session]);

  useEffect(() => {
    if (
      timeoutMins &&
      session.status !== callStatus.EXPIRED &&
      session.status !== callStatus.FAILED &&
      session.status !== callStatus.IN_PROCESS
    ) {
      const timer = new IdleTimer({
        timeout: timeoutMins,
        onTimeout: () => {
          dispatch(doExpired());
        },
        onExpired: () => {
          dispatch(doLogout());
          forceLogoutRef.current = false;
        },
      });

      return () => {
        timer.cleanUp();
      };
    }
    // eslint-disable-next-line
  }, [timeoutMins, session]);

  const getInit = () => {
    return modules.find((value) => value.url !== '' && value.autoexec);
  };

  const handleShowIframe = (module) => {
    dispatch(showIframe(module));
  };

  return (
    <Container>
      <LayoutStyle />
      <Spin spinning={main.status === callStatus.IN_PROCESS}>
        {main.status === callStatus.FAILED && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              height: '100%',
              justifyContent: 'center',
            }}
          >
            <Alert
              message="Error"
              description={main.error}
              type="error"
              showIcon
            />
          </div>
        )}
        {main.status === callStatus.SUCCESS && (
          <Layout
            style={{
              height: '100vh',
              filter:
                session.status === callStatus.EXPIRED ||
                session.status === callStatus.FAILED ||
                session.status === callStatus.IN_PROCESS
                  ? 'blur(0.3rem)'
                  : '',
            }}
          >
            {main.data.top_banner && <TopBanner data={main.data.top_banner} />}

            <Header>
              <HeaderContainer>
                <HeaderIcons
                  style={{
                    backgroundColor: colors.header.icons[color],
                    paddingBottom: '2px',
                  }}
                >
                  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 26">
                    <path
                      fill="#FFF"
                      fillRule="evenodd"
                      d="M8.021 2.75L10.947.503 21.48 7.24v12.494l-10.808 6.129-3.262-1.848 10.49-6.051-.018-8.952L8.02 2.75zM5.598 4.054L8.745 5.88 3.28 9.014v8.737l5.487 3.314-3.107 1.877L0 19.632V7.264l5.6-3.211zm5.086 2.98l5.558 3.2-.136 6.481-5.41 3.144-5.751-3.228v-6.319l3.32-1.93v6.558l2.333 1.276 2.47-1.194V12.03l-2.405-1.448.02-3.549z"
                    />
                  </Svg>
                </HeaderIcons>
                <HeaderMenu>
                  <Nav
                    modules={modules}
                    handleSetUrl={handleShowIframe}
                    activeItem={activeItem}
                  />
                </HeaderMenu>

                <HeaderMore
                  style={{
                    backgroundColor: colors.header.more[color],
                  }}
                >
                  <More
                    modules={modules}
                    handleSetUrl={handleShowIframe}
                    activeItem={activeItem}
                    search={main.data.search}
                    forceLogoutRef={forceLogoutRef}
                  />
                </HeaderMore>
              </HeaderContainer>
            </Header>
            <Content>
              {iframes.map((item, index) => (
                <Iframe
                  url={item.url.replace(
                    '#work_sess_user#',
                    auth.data.user ? auth.data.user : auth.data.new_user
                  )}
                  refresh={item.refresh}
                  show={item.showPanel}
                  key={index}
                />
              ))}
            </Content>
          </Layout>
        )}
      </Spin>
      {session.status === callStatus.EXPIRED ||
      session.status === callStatus.FAILED ? (
        <Modal
          visible={true}
          closable={false}
          title={false}
          footer={false}
          centered={true}
          bodyStyle={{ height: '400px' }}
        >
          <Login modalOpen={modalOpen} />
        </Modal>
      ) : (
        false
      )}
    </Container>
  );
};

export default withRouter(Main);
