import React from 'react';
import styled from 'styled-components';
import { API_URL } from '../utils/global';
import withReactMemo from '../utils/withReactMemo';

const IframeStyled = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;

const Iframe = ({ url, refresh, show }) => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: show ? 1 : 0,
        background: '#ffffff',
      }}
    >
      <IframeStyled
        src={`${API_URL + url}`}
        title={url}
        key={`${refresh}${url}`}
      ></IframeStyled>
    </div>
  );
};

export default withReactMemo('Iframe', Iframe);
