import Icon from '@mdi/react';
import React from 'react';

const getIcon = (icon, fontSize = '18px', color, markdown) => {
  if (icon) {
    let pre = icon.split(':')[0];
    let val = icon.split(':')[1];
    fontSize = fontSize ? fontSize : '18px';

    switch (pre) {
      case 'antd': {
        const icons = require(`@ant-design/icons`);
        let CustomIcon = icons[val];
        return CustomIcon && !markdown ? (
          <CustomIcon
            style={{
              fontSize: fontSize,
              verticalAlign: 'middle',
            }}
            key={`${pre}.${val}`}
          />
        ) : (
          `<CustomIcon key={${pre}.${val}} />`
        );
      }
      case 'mdi': {
        const icons = require('../utils/mdi');
        let CustomIcon = icons[val];
        return (
          <Icon
            path={CustomIcon}
            size={fontSize}
            color={color}
            style={{ verticalAlign: 'middle' }}
          />
        );
      }
      case 'internal': {
        if (val.includes('mono')) {
          return val.includes('sl-cancelled') ? (
            <div
              style={{
                fontFamily: 'Status Icons',
                fontSize: fontSize,
                color: '#000000',
                display: 'flex',
                alignItems: 'center',
                lineHeight: 1,
              }}
            >
              &#xe900;
            </div>
          ) : val.includes('sl-comments') ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Icon
                path={
                  'M18,8H6V6H18V8M18,11H6V9H18V11M18,14H6V12H18V14M22,4A2,2 0 0,0 20,2H4A2,2 0 0,0 2,4V16A2,2 0 0,0 4,18H18L22,22V4Z'
                }
                size={fontSize}
                color={'#000000'}
              />
            </div>
          ) : val.includes('sl-valtech') ? (
            <span
              className="sl-valtech"
              style={{
                fontFamily: 'Status Icons',
                fontSize: fontSize,
                color: '#000000',
                display: 'flex',
                alignItems: 'center',
                lineHeight: 1,
              }}
            >
              &#xe904;
            </span>
          ) : val.includes('sl-ordered') ? (
            <div
              style={{
                fontFamily: 'Status Icons',
                fontSize: fontSize,
                color: '#000000',
                display: 'flex',
                alignItems: 'center',
                lineHeight: 1,
              }}
            >
              &#xe901;
            </div>
          ) : val.includes('sl-received') ? (
            <div
              style={{
                transform: 'rotate(180deg)',
              }}
            >
              <span
                style={{
                  fontFamily: 'Status Icons',
                  fontSize: fontSize,
                  color: '#000000',
                  display: 'flex',
                  alignItems: 'center',
                  lineHeight: 1,
                }}
              >
                &#xe902;
              </span>
            </div>
          ) : val.includes('sl-rerun') ? (
            <span
              style={{
                fontFamily: 'Status Icons',
                fontSize: fontSize,
                color: '#000000',
                display: 'flex',
                alignItems: 'center',
                lineHeight: 1,
              }}
            >
              &#xe903;
            </span>
          ) : val.includes('sl-validated') ? (
            <span
              style={{
                fontFamily: 'Status Icons',
                fontSize: fontSize,
                color: '#000000',
                display: 'flex',
                alignItems: 'center',
                lineHeight: 1,
              }}
            >
              &#xe904;
            </span>
          ) : null;
        }

        return val === 'sl-cancelled' ? (
          <div
            style={{
              fontFamily: 'Status Icons',
              fontSize: fontSize,
              color: '#ff5252',
              display: 'flex',
              alignItems: 'center',
              lineHeight: 1,
            }}
          >
            &#xe900;
          </div>
        ) : val === 'sl-comments' ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Icon
              path={
                'M18,8H6V6H18V8M18,11H6V9H18V11M18,14H6V12H18V14M22,4A2,2 0 0,0 20,2H4A2,2 0 0,0 2,4V16A2,2 0 0,0 4,18H18L22,22V4Z'
              }
              size={fontSize}
              color="#000000"
            />
          </div>
        ) : val === 'sl-valtech' ? (
          <span
            className="sl-valtech"
            style={{
              fontFamily: 'Status Icons',
              fontSize: fontSize,
              color: '#e77a14',
              display: 'flex',
              alignItems: 'center',
              lineHeight: 1,
            }}
          >
            &#xe904;
          </span>
        ) : val === 'sl-ordered' ? (
          <span
            style={{
              fontFamily: 'Status Icons',
              fontSize: fontSize,
              color: '#546e7a',
              display: 'flex',
              alignItems: 'center',
              lineHeight: 1,
            }}
          >
            &#xe901;
          </span>
        ) : val === 'sl-received' ? (
          <div
            style={{
              transform: 'rotate(180deg)',
              float: 'left',
              lineHeight: 1,
            }}
          >
            <span
              className="sl-received"
              style={{
                fontFamily: 'Status Icons',
                fontSize: fontSize,
                color: '#ff6f07',
                display: 'flex',
                alignItems: 'center',
                lineHeight: 1,
              }}
            >
              &#xe902;
            </span>
          </div>
        ) : val === 'sl-rerun' ? (
          <span
            style={{
              fontFamily: 'Status Icons',
              fontSize: fontSize,
              color: '#546e7a',
              display: 'flex',
              alignItems: 'center',
              lineHeight: 1,
            }}
          >
            &#xe903;
          </span>
        ) : val === 'sl-validated' ? (
          <span
            style={{
              fontFamily: 'Status Icons',
              fontSize: fontSize,
              color: '#81ac51',
              display: 'flex',
              alignItems: 'center',
              lineHeight: 1,
            }}
          >
            &#xe904;
          </span>
        ) : null;
      }
      default:
        break;
    }
  }

  return null;
};

export default getIcon;
