const LOAD_NPM = false;

export function importUseCli() {
  const npm_path = '@smartlis/sl-cli';
  const pkg_path = '../packages/sl-cli/src';
  const pkg_name = 'useCli';

  const load_npm = () => {
    try {
      let { [pkg_name]: result } = require('@smartlis/sl-cli');
      if (result) {
        return result;
      }
    } catch (exc) {
      console.log(`'${npm_path}' not exists: `);
      throw exc;
    }
  };

  let component = null;
  if (process.env.NODE_ENV === 'production' || LOAD_NPM === true) {
    component = load_npm();
  } else {
    component = null;
    try {
      let { [pkg_name]: result } = require('../packages/sl-cli/src');
      if (result) {
        console.log(`'${pkg_path}' exists: loading ${pkg_name}`);
        component = result;
      }
    } catch (exc) {
      console.log(`'${pkg_path}' not exists: `);
    }

    if (!component) {
      component = load_npm();
    }
  }

  return component;
}

export function importCliHandler() {
  const npm_path = '@smartlis/sl-cli';
  const pkg_path = '../packages/sl-cli/src';
  const pkg_name = 'useCliHandler';

  const load_npm = () => {
    try {
      let { [pkg_name]: result } = require('@smartlis/sl-cli');
      if (result) {
        return result;
      }
    } catch (exc) {
      console.log(`'${npm_path}' not exists: `);
      throw exc;
    }
  };

  let component = null;
  if (process.env.NODE_ENV === 'production' || LOAD_NPM === true) {
    component = load_npm();
  } else {
    component = null;
    try {
      let { [pkg_name]: result } = require('../packages/sl-cli/src');
      if (result) {
        console.log(`'${pkg_path}' exists: loading ${pkg_name}`);
        component = result;
      }
    } catch (exc) {
      console.log(`'${pkg_path}' not exists: `);
    }

    if (!component) {
      component = load_npm();
    }
  }

  return component;
}

export function importCliWidget() {
  const npm_path = '@smartlis/sl-cli';
  const pkg_path = '../packages/sl-cli/src';
  const pkg_name = 'CliWidget';

  const load_npm = () => {
    try {
      let { [pkg_name]: result } = require('@smartlis/sl-cli');
      if (result) {
        console.log(`${npm_path} exists: loading ${pkg_name}`);
        return result;
      }
    } catch (exc) {
      console.log(`'${npm_path}' not exists: `);
      throw exc;
    }
  };

  let component = null;
  if (process.env.NODE_ENV === 'production' || LOAD_NPM === true) {
    component = load_npm();
  } else {
    component = null;
    try {
      let { [pkg_name]: result } = require('../packages/sl-cli/src');
      if (result) {
        console.log(`'${pkg_path}' exists: loading ${pkg_name}`);
        component = result;
      }
    } catch (exc) {
      console.log(`'${pkg_path}' not exists: `);
    }

    if (!component) {
      component = load_npm();
    }
  }

  return component;
}

export function importCliProvider() {
  const npm_path = '@smartlis/sl-cli';
  const pkg_path = '../packages/sl-cli/src';
  const pkg_name = 'CliProvider';

  const load_npm = () => {
    try {
      let { [pkg_name]: result } = require('@smartlis/sl-cli');
      if (result) {
        return result;
      }
    } catch (exc) {
      console.log(`'${npm_path}' not exists: `);
      throw exc;
    }
  };

  let component = null;
  if (process.env.NODE_ENV === 'production' || LOAD_NPM === true) {
    component = load_npm();
  } else {
    component = null;
    try {
      let { [pkg_name]: result } = require('../packages/sl-cli/src');
      if (result) {
        console.log(`'${pkg_path}' exists: loading ${pkg_name}`);
        component = result;
      }
    } catch (exc) {
      console.log(`'${pkg_path}' not exists: `);
    }

    if (!component) {
      component = load_npm();
    }
  }

  return component;
}

export function importCliTerminal() {
  const npm_path = '@smartlis/sl-cli';
  const pkg_path = '../packages/sl-cli/src';
  const pkg_name = 'CliTerminal';

  const load_npm = () => {
    try {
      let { [pkg_name]: result } = require('@smartlis/sl-cli');
      if (result) {
        console.log(`${npm_path} exists: loading ${pkg_name}`);
        return result;
      }
    } catch (exc) {
      console.log(`'${npm_path}' not exists: `);
      throw exc;
    }
  };

  let component = null;
  if (process.env.NODE_ENV === 'production' || LOAD_NPM === true) {
    component = load_npm();
  } else {
    component = null;
    try {
      let { [pkg_name]: result } = require('../packages/sl-cli/src');
      if (result) {
        console.log(`'${pkg_path}' exists: loading ${pkg_name}`);
        component = result;
      }
    } catch (exc) {
      console.log(`'${pkg_path}' not exists: `);
    }

    if (!component) {
      component = load_npm();
    }
  }

  return component;
}

export function importCliTerminalModal() {
  const npm_path = '@smartlis/sl-cli';
  const pkg_path = '../packages/sl-cli/src';
  const pkg_name = 'CliTerminalModal';

  const load_npm = () => {
    try {
      let { [pkg_name]: result } = require('@smartlis/sl-cli');
      if (result) {
        console.log(`${npm_path} exists: loading ${pkg_name}`);
        return result;
      }
    } catch (exc) {
      console.log(`'${npm_path}' not exists: `);
      throw exc;
    }
  };

  let component = null;
  if (process.env.NODE_ENV === 'production' || LOAD_NPM === true) {
    component = load_npm();
  } else {
    component = null;
    try {
      let { [pkg_name]: result } = require('../packages/sl-cli/src');
      if (result) {
        console.log(`'${pkg_path}' exists: loading ${pkg_name}`);
        component = result;
      }
    } catch (exc) {
      console.log(`'${pkg_path}' not exists: `);
    }

    if (!component) {
      component = load_npm();
    }
  }

  return component;
}

export function importCliMain() {
  const npm_path = '@smartlis/sl-cli';
  const pkg_path = '../packages/sl-cli/src';
  const pkg_name = 'CliMain';

  const load_npm = () => {
    try {
      let { [pkg_name]: result } = require('@smartlis/sl-cli');
      if (result) {
        console.log(`${npm_path} exists: loading ${pkg_name}`);
        return result;
      }
    } catch (exc) {
      console.log(`'${npm_path}' not exists: `);
      throw exc;
    }
  };

  let component = null;
  if (process.env.NODE_ENV === 'production' || LOAD_NPM === true) {
    component = load_npm();
  } else {
    component = null;
    try {
      let { [pkg_name]: result } = require('../packages/sl-cli/src');
      if (result) {
        console.log(`'${pkg_path}' exists: loading ${pkg_name}`);
        component = result;
      }
    } catch (exc) {
      console.log(`'${pkg_path}' not exists: `);
    }

    if (!component) {
      component = load_npm();
    }
  }

  return component;
}

export function importCliCommandParser() {
  const npm_path = '@smartlis/sl-cli';
  const pkg_path = '../packages/sl-cli/src';
  const pkg_name = 'CommandParser';

  const load_npm = () => {
    try {
      let { [pkg_name]: result } = require('@smartlis/sl-cli');
      if (result) {
        console.log(`${npm_path} exists: loading ${pkg_name}`);
        return result;
      }
    } catch (exc) {
      console.log(`'${npm_path}' not exists: `);
      throw exc;
    }
  };

  let component = null;
  if (process.env.NODE_ENV === 'production' || LOAD_NPM === true) {
    component = load_npm();
  } else {
    component = null;
    try {
      let { [pkg_name]: result } = require('../packages/sl-cli/src');
      if (result) {
        console.log(`'${pkg_path}' exists: loading ${pkg_name}`);
        component = result;
      }
    } catch (exc) {
      console.log(`'${pkg_path}' not exists: `);
    }

    if (!component) {
      component = load_npm();
    }
  }

  return component;
}
