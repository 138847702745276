import { css } from '@emotion/css';
import Icon from '@mdi/react';
import { Dropdown, Menu } from 'antd';
import { isEqual } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import colors from '../constants/colors';
import { mdiDotsHorizontal } from '../utils/mdi';
import getIcon from '../utils/useIcon';
import './Nav.css';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const ContainerNav = styled.div`
  flex: 1;
`;

const ContainerSubmenu = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 36px;

  position: absolute;
`;
const ItemMore = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 100%;
  border-bottom: 3px solid transparent;
`;
const Nav = ({ modules, handleSetUrl, activeItem }) => {
  const [submenu, setSubmenu] = useState({ dist: 0, data: [] });
  const icons = require(`@ant-design/icons`);
  const navRef = useRef(null);
  const subRef = useRef(null);
  const context = useSelector(({ context }) => context);
  const { color } = context;

  let timeout = false;
  let EmptyIcon = icons['BorderOutlined'];

  useEffect(() => {
    const updateMenu = () => {
      if (navRef.current) {
        const tamMax =
          submenu.data.length > 0
            ? navRef.current.clientWidth - 35
            : navRef.current.clientWidth;

        let tam = 0;
        let dist = 0;
        let auxSubmenu = [];
        modules &&
          modules.forEach((item) => {
            var itemDiv = document.getElementById(`item-${item.id}`);
            if (itemDiv) {
              tam = tam + itemDiv.clientWidth + 15;

              if (tam > tamMax) {
                auxSubmenu.push(item);
              } else {
                dist = tam;
              }
            }
          });

        if (!isEqual(auxSubmenu, submenu.data)) {
          setSubmenu({ dist: dist, data: auxSubmenu });
        }
      }
    };

    updateMenu();

    window.addEventListener('resize', function () {
      clearTimeout(timeout);
      // eslint-disable-next-line
      timeout = setTimeout(updateMenu, 500);
    });
  });

  const subMenuDrop = (
    <Menu
      className={css`
        background-color: ${colors.header.nav.menu[color]};

        .ant-dropdown-menu-item:hover {
          background-color: ${colors.header.nav.menu[color]};
          border-left: 3px solid white;
        }
        padding-right: 8px;
      `}
    >
      {submenu.data.map((item) => (
        <Menu.Item
          key={item.id}
          onClick={() => handleSetUrl(item)}
          style={{
            borderLeft: item.id === activeItem.id && '3px solid white',
          }}
        >
          <span>{item.name}</span>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Container>
      <ContainerNav>
        <nav
          ref={navRef}
          style={{
            backgroundColor: colors.header.nav[color],
          }}
          className={css`
            ul li {
              background-color: ${colors.header.nav.li[color]};
              border-bottom: 3px solid ${colors.header.nav.li[color]};
            }
            li:hover {
              border-bottom: 3px solid ${colors.header.nav.menu.hover[color]};
              cursor: pointer;
            }
          `}
        >
          <ul>
            {modules &&
              modules.map((item) => {
                const iconComponent = item.image ? (
                  getIcon(item.image, '18px', 'white')
                ) : (
                  <EmptyIcon
                    style={{
                      fontSize: '18px',
                      verticalAlign: 'middle',
                      visibility: 'hidden',
                      width: 0,
                    }}
                    key={`empty`}
                  />
                );
                if (!item.alignment || item.alignment === 'left') {
                  return (
                    <li
                      id={`item-${item.id}`}
                      key={`item-${item.id}`}
                      onClick={() => handleSetUrl(item)}
                      style={{
                        borderBottom:
                          item.id === activeItem.id && '3px solid white',
                        visibility: submenu.data.find(
                          (value) => value.id === item.id
                        )
                          ? 'collapse'
                          : 'visible',
                      }}
                    >
                      <div className="info" style={{}}>
                        <div className="info-text" style={{}}>
                          {/* {item.image && iconComponent} */}
                          {iconComponent}
                          <div
                            style={
                              item.image && item.name
                                ? {
                                    marginLeft: '5px',
                                  }
                                : {}
                            }
                          >
                            {item.name}
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                } else {
                  return null;
                }
              })}
          </ul>
        </nav>
      </ContainerNav>
      {submenu.data.length > 0 && (
        <ContainerSubmenu
          ref={subRef}
          style={{
            zIndex: 5,
            left: submenu.dist + 50,
          }}
        >
          <Dropdown overlay={subMenuDrop} placement="bottomRight">
            <ItemMore
              style={{
                borderBottom:
                  submenu.data.some((item) => item.id === activeItem.id) &&
                  '3px solid white',
              }}
            >
              <Icon
                path={mdiDotsHorizontal}
                title=""
                size={'20px'}
                color="white"
              />
            </ItemMore>
          </Dropdown>
        </ContainerSubmenu>
      )}
    </Container>
  );
};
export default Nav;
