import {
  NEW_CONSOLE_OUTPUT,
  SET_CONSOLE_OUTPUT,
  SET_ALERT,
  SET_NOTIFICATION,
  BACKEND_VERSION,
  SET_COLOR,
  CONFIG_VARS,
} from './types/ContextActionTypes';

const initialState = {
  alert: null,
  consoleOutput: [],
  notification: [],
  backendVersion: null,
  refresh: false,
  color: null,
  configVars: null,
};

const ContextReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case NEW_CONSOLE_OUTPUT: {
      newState = {
        ...state,
        consoleOutput: [],
      };
      return newState;
    }

    case SET_CONSOLE_OUTPUT: {
      newState = {
        ...state,
        consoleOutput: [...state.consoleOutput, action.data],
      };
      return newState;
    }

    case SET_ALERT: {
      newState = state;

      if (action.alertType && action.value) {
        const item = {
          type: action.alertType,
          title: action.title,
          value: action.value,
        };
        newState = { ...newState, alert: item };
      } else {
        newState = { ...newState, alert: null };
      }
      return newState;
    }

    case SET_NOTIFICATION: {
      newState = {
        ...state,
        notification:
          action.notType && action.value
            ? state.notification
              ? [
                  ...state.notification,
                  {
                    type: action.notType,
                    title: action.title,
                    value: action.value,
                  },
                ]
              : [
                  {
                    type: action.notType,
                    title: action.title,
                    value: action.value,
                  },
                ]
            : null,
      };

      return newState;
    }

    case BACKEND_VERSION: {
      newState = {
        ...state,
        backendVersion: action.data,
      };

      return newState;
    }
    case SET_COLOR: {
      newState = {
        ...state,
        color: action.value,
      };
      return newState;
    }
    case CONFIG_VARS: {
      newState = {
        ...state,
        configVars: action.data,
      };
      return newState;
    }
    default:
      return state;
  }
};

export default ContextReducer;
