import { css } from '@emotion/css';
import { Dropdown, Input, Menu, Tooltip } from 'antd';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import colors from '../constants/colors';
import './More.css';

import Icon from '@mdi/react';
import { doLogout } from '../redux/AuthActions';
import { doSearch, doShowQuery, refreshIframe } from '../redux/MainActions';
import { importUseCli } from '../utils/dynamic-imports-sl-cli';
import {
  mdiAccount,
  mdiConsole,
  mdiDotsVertical,
  mdiLockOutline,
  mdiMagnify,
  mdiMonitorScreenshot,
  mdiPower,
  mdiRefresh,
} from '../utils/mdi';
import getIcon from '../utils/useIcon';
import About from './About';
import ChangePassword from './ChangePassword';
import ChangeWorkstation from './ChangeWorkstation';

const ItemMore = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 12px;
  height: 100%;
`;
const ItemMore2 = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 12px;
  border-bottom: 3px solid #1c76d2;
  height: 36px;
  // &:hover {
  //   border-bottom: 3px solid white;
  // }
  padding-top: 3px;
`;
const MenuItemText = styled.div`
  margin-left: 7px;
`;

const useCli = importUseCli();

const More = ({
  modules,
  handleSetUrl,
  activeItem,
  search,
  forceLogoutRef,
}) => {
  const auth = useSelector(({ auth }) => auth);
  const iframes = useSelector(({ main: { iframes } }) => iframes);
  const translations = useSelector(({ translations }) => translations);

  const dispatch = useDispatch();
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showChangeWorkstation, setShowChangeWorkstation] = useState(false);
  const [showCli, setShowCli] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [inputSearch, setInputSearch] = useState('');
  const context = useSelector(({ context }) => context);
  const { color } = context;

  const cli = useCli();

  const handleRefresh = () => {
    dispatch(refreshIframe());
  };

  const userMenu = (
    <Menu
      style={{
        paddingRight: '10px',
        backgroundColor: colors.header.more[color],
        color: 'white',
        marginTop: '0px',
        padding: '5px 0px',
      }}
      className={css`
        .ant-dropdown-menu-item:hover {
          background-color: ${colors.header.more.hover[color]};
          border-left: 3px solid white;
        }
      `}
    >
      <Menu.Item
        onClick={() => setShowChangePassword(true)}
        icon={
          <Icon path={mdiLockOutline} title="" size={'20px'} color="white" />
        }
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <MenuItemText>
          {translations.data.navegation.change_password}
        </MenuItemText>
      </Menu.Item>
    </Menu>
  );

  const moduleMenu = (
    <Menu
      style={{
        paddingRight: '10px',
        backgroundColor: colors.header.more[color],
        color: 'white',
        marginTop: '0px',
        padding: '5px 0px',
      }}
      className={css`
        .ant-dropdown-menu-item:hover {
          background-color: ${colors.header.more.hover[color]};
          border-left: 3px solid white;
        }
      `}
    >
      <Menu.Item
        onClick={() => handleRefresh()}
        icon={<Icon path={mdiRefresh} title="" size={'20px'} color="white" />}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <MenuItemText>{translations.data.navegation.refresh}</MenuItemText>
      </Menu.Item>

      <Menu.Item
        onClick={() => setShowCli(true)}
        icon={<Icon path={mdiConsole} title="" size={'20px'} color="white" />}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <MenuItemText>{translations.data.navegation.about}</MenuItemText>
      </Menu.Item>
    </Menu>
  );

  const handleOnSearch = async (e) => {
    if (e.target.value?.startsWith('$')) {
      cli.execCommand(e.target.value.replace('$', ''));
    } else {
      doSearch(e.target.value, dispatch);
    }
    setShowSearch(false);
    setInputSearch('');
  };

  const handleOnChangeSearch = (e) => {
    e.preventDefault();
    setInputSearch(e.target.value);
    if (e.target.value !== '') {
      setShowSearch(true);
    }
  };

  const inputSearchRef = useRef(false);

  return (
    <>
      {search && (
        <div>
          <Input
            placeholder=""
            ref={inputSearchRef}
            prefix={
              <Icon
                path={mdiMagnify}
                title=""
                size={'20px'}
                color={
                  iframes.filter((value) => value.id.startsWith('CUSTOM_QUERY'))
                    .length
                    ? 'white'
                    : '#90a4ae'
                }
                style={{
                  marginLeft: '3px',
                  cursor: 'pointer',
                }}
                onClick={(e) =>
                  iframes.filter((value) => value.id.startsWith('CUSTOM_QUERY'))
                    .length
                    ? doShowQuery(dispatch)
                    : {}
                }
              />
            }
            allowClear
            onPressEnter={(e) => handleOnSearch(e)}
            onClick={() => setShowSearch(true)}
            value={inputSearch}
            onChange={(e) => handleOnChangeSearch(e)}
            onBlur={() => {
              if (inputSearch === '') {
                setShowSearch(false);
              }
            }}
            size="small"
            style={{
              marginLeft: '12px',
              transition: ' width .5s',
              width: showSearch ? '350px' : '120px',
              background: colors.header.more.input[color],

              paddingLeft: 0,
            }}
            className={css`
              .ant-input-clear-icon {
                color: white;
              }
              .ant-input {
                background-color: ${colors.header.more.input[color]} !important;
                color: white;
              }

              &.ant-input-affix-wrapper {
                border: 1px solid ${colors.header.more.input.border[color]} !important;
              }
              &.ant-input-affix-wrapper:hover {
                border: 1px solid ${colors.header.more.hover[color]} !important;
              }
              &.ant-input-affix-wrapper-focused {
                border: 1px solid ${colors.header.more.hover[color]} !important;
              }
            `}
          />
        </div>
      )}
      {modules &&
        modules.map((item, index) => {
          const iconComponent = item.image
            ? getIcon(item.image, '18px', 'white')
            : null;
          if (item.alignment === 'right') {
            return (
              <ItemMore2
                style={{
                  borderBottom: item.id === activeItem.id && '3px solid white',
                }}
                className={css`
                  &:hover {
                    border-bottom: 3px solid
                      ${colors.header.nav.menu.hover[color]};
                    cursor: pointer;
                  }
                `}
                key={index}
              >
                <Tooltip
                  placement="bottom"
                  title={item.name}
                  mouseLeaveDelay={0}
                >
                  {iconComponent && (
                    <div onClick={() => handleSetUrl(item)}>
                      {iconComponent}
                    </div>
                  )}
                </Tooltip>
              </ItemMore2>
            );
          } else {
            return null;
          }
        })}
      <div
        style={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          borderLeft:
            modules &&
            modules.filter((item) => item.alignment === 'right').length > 0
              ? '1px solid #fffffe21'
              : 'none',
          marginLeft:
            modules &&
            modules.filter((item) => item.alignment === 'right').length > 0
              ? '12px'
              : 0,
        }}
      >
        <ItemMore
          style={{
            marginLeft: '12px',
          }}
        >
          <Tooltip
            placement="bottom"
            title={translations.data.navegation.workstation}
            mouseLeaveDelay={0}
          >
            <Icon
              onClick={() => setShowChangeWorkstation(true)}
              path={mdiMonitorScreenshot}
              title=""
              size={'20px'}
              color="white"
            />
          </Tooltip>
        </ItemMore>
        <Dropdown overlay={userMenu}>
          <ItemMore>
            <Icon
              path={mdiAccount}
              title=""
              size={'20px'}
              color="white"
              style={{
                marginRight: '3px',
              }}
            />
            <span>{auth.data.user ? auth.data.user : auth.data.new_user}</span>
          </ItemMore>
        </Dropdown>

        <Dropdown overlay={moduleMenu}>
          <ItemMore>
            <Icon path={mdiDotsVertical} title="" size={'20px'} color="white" />
          </ItemMore>
        </Dropdown>
        <ItemMore>
          <Tooltip
            placement="bottomRight"
            title={translations.data.navegation.logout}
            mouseLeaveDelay={0}
          >
            <Icon
              onClick={() => {
                dispatch(doLogout());
                forceLogoutRef.current = false;
              }}
              path={mdiPower}
              title=""
              size={'20px'}
              color="white"
            />
          </Tooltip>
        </ItemMore>
      </div>

      {showChangePassword && (
        <ChangePassword closeModal={() => setShowChangePassword(false)} />
      )}

      {showChangeWorkstation && (
        <ChangeWorkstation closeModal={() => setShowChangeWorkstation(false)} />
      )}

      <About open={showCli} onCancel={() => setShowCli(false)} />
    </>
  );
};
export default More;
