import store from '../store';
import {
  doAlert,
  doNotification,
  clear,
  setConsoleOutput,
} from '../ContextAction';

import {
  ALERT,
  CLEAR_CONSOLE,
  HELP,
  NOTIFICATION,
  REFRESH,
  DUPLICATE,
  OPEN_TAB,
} from './commandTypes';
import commandDef from './commandsDef.json';

import { refreshIframe, duplicateIframe, openTabIframe } from '../MainActions';

const cmdAlert = (command) => (dispatch) => {
  switch (command.params.type) {
    case 'success':
    case 'error':
    case 'info':
    case 'warning': {
      dispatch(
        doAlert(command.params.type, command.params.title, command.params.value)
      );

      break;
    }
    default: {
      dispatch(
        doNotification(
          'error',
          `Invalid alert type`,
          `'${command.params.type}' is not a valid alert type`
        )
      );

      break;
    }
  }
};

const cmdClearConsole = (command) => (dispatch) => {
  dispatch(clear());
};

const getOutputHelp = () => {
  let output = '';
  output = `${store.getState().translations.data.cli.valid_commands}:\n`;

  Object.entries(commandDef.commands).map(([key, value]) => {
    let paramsStr = '';

    value.parameters.map((value) => {
      let param = `${value.acronym ? `${value.acronym}:` : ''}${value.name}`;
      param = !value.mandatory ? `[${param}] ` : `${param} `;
      paramsStr += param;

      if (value.description) {
        paramsStr += `(${value.description}) `;
      }

      return false;
    });

    let command = `        ${value.acronym ? `${value.acronym}:` : ''}${
      value.name
    } ${paramsStr}\n`;

    output += command;

    return false;
  });

  return output;
};

const cmdNotification = (command) => (dispatch) => {
  switch (command.params.type) {
    case 'success':
    case 'error':
    case 'info':
    case 'warning': {
      dispatch(
        doNotification(
          command.params.type,
          command.params.title,
          command.params.value
        )
      );

      break;
    }
    default: {
      dispatch(
        doNotification(
          'error',
          `Invalid notification type`,
          `'${command.params.type}' is not a valid notification type`
        )
      );

      break;
    }
  }
};

const cmdRefresh = (command) => (dispatch) => {
  dispatch(refreshIframe());
};

const cmdDuplicate = (command) => (dispatch) => {
  dispatch(duplicateIframe());
};

const cmdOpenTab = (command) => {
  openTabIframe();
};

const getCommandText = (command) => {
  const paramsText = Object.entries(command.params)
    .map(([key, value]) => {
      if (/\s/.test(value)) {
        return `${key}:'${value}'`;
      }
      return `${key}:${value}`;
    })
    .join(' ');

  let commandText = `${command.user ? '$ ' : ''}${command.type} ${paramsText}`;

  let commandTextUser = null;
  if (command.commandText) {
    commandTextUser = command.commandText;
  }

  return [commandText, commandTextUser];
};

export const doAddConsoleLine = ({ text, shortText, userText, errors }) => {
  return (dispatch) => {
    try {
      if (errors) {
        let errorsText = '';
        if (Array.isArray(errors)) {
          errors.forEach((elem, index) => {
            errorsText = errorsText.concat(
              index > 0 ? `\nError: ${elem.message}` : `Error: ${elem.message}`
            );
          });
        } else if (typeof errors === 'object') {
          errorsText = errors.message;
        }

        dispatch(setConsoleOutput({ text: errorsText, userText }));
      } else {
        if (typeof text === 'object') {
          text = JSON.stringify(text);
        }

        dispatch(setConsoleOutput({ text, shortText, userText }));
      }
    } catch (error) {
      throw error;
    }
  };
};

export const doExecCommand = async (dispatch, command, optional) => {
  let commandText = getCommandText(command);
  await dispatch(
    doAddConsoleLine({ text: commandText[0], userText: commandText[1] })
  );

  switch (command.type) {
    case ALERT: {
      await dispatch(cmdAlert(command));
      break;
    }

    case CLEAR_CONSOLE: {
      await dispatch(cmdClearConsole(command));
      break;
    }

    case HELP: {
      await dispatch(doAddConsoleLine({ text: getOutputHelp() }));
      break;
    }

    case NOTIFICATION: {
      await dispatch(cmdNotification(command));
      break;
    }

    case REFRESH: {
      await dispatch(cmdRefresh(command));
      break;
    }

    case DUPLICATE: {
      await dispatch(cmdDuplicate(command));
      break;
    }

    case OPEN_TAB: {
      cmdOpenTab(command);
      break;
    }

    default: {
      dispatch(command);
      break;
    }
  }
};
