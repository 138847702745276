import { API_URL } from '../utils/global';

const axiosDef = require('axios').default;

export const defaultErrorHandler = (axiosError) => {
  if (
    axiosError.response &&
    [301, 302, 303, 307, 308].includes(axiosError.response.status) &&
    axiosError.response.headers['x-location']
  ) {
    window.location.href = axiosError.response.headers['x-location'];
  }

  let error = null;
  let code = null;
  if (axiosError.response && axiosError.response.data) {
    error = axiosError.response.data.errors
      ? axiosError.response.data.errors
      : axiosError.response.data.message
      ? axiosError.response.data.message
      : 'unknown_error';
    code = axiosError.response.data.code;
  } else {
    error = axiosError.message ? axiosError.message : 'unknown_error';
    code = axiosError.code;
  }

  if (
    axiosError.config.url !== '/login/' &&
    axiosError.response &&
    axiosError.response.status === 401
  ) {
    window.location.reload();
  }
  return Promise.reject({
    code: code,
    message: error,
    status: axiosError.response?.status || null,
  });
};

export const defaultSuccesHandler = (response) => {
  return Promise.resolve(response.data);
};

const getTimeoutDuration = (retryCount) => {
  return 2000 * Math.pow(2, retryCount);
};

export function createApi({
  baseURL = `${API_URL}`,
  timeout = 2000,
  retry = { retryCount: 4 },
  headers = { 'Content-Type': 'application/json' },
  withCredentials = true,
  errorHandler = defaultErrorHandler,
  successHandler = defaultSuccesHandler,
  axios = axiosDef.create(),
}) {
  axios.defaults.baseURL = baseURL;
  axios.defaults.timeout = timeout;
  axios.defaults.retry = retry;
  axios.defaults.headers = headers;
  axios.defaults.withCredentials = withCredentials;
  axios.defaults.validateStatus = (status) => {
    return status < 300;
  };
  axios.interceptors.response.use(
    (response) => successHandler(response),
    (error) => {
      const config = error.config;
      if (!config || !config.retry || config.retryCount >= 5) {
        return Promise.reject(error);
      }
      config.retryCount = config.retryCount || 0;
      config.retryCount += 1;

      config.timeout = getTimeoutDuration(config.retryCount);

      const backoff = new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, config.timeout);
      });

      backoff.then(() => axios(config));

      return errorHandler(error);
    }
  );
  return axios;
}

export const authApi = createApi({
  baseURL: `${API_URL}/system/`,
});

export const mainApi = createApi({
  baseURL: `${API_URL}/userinterface/get_main_design/`,
});

export const genericApi = createApi({
  baseURL: `${API_URL}`,
});

export const translationApi = createApi({
  baseURL: `${API_URL}/system/translation_ng/UI_NG/sl-main/`,
});

export const systemApi = createApi({
  baseURL: `${API_URL}/system`,
});
