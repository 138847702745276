import {
  TRANSLATION_LOGIN_PENDING,
  TRANSLATION_LOGIN_IN_PROCESS,
  TRANSLATION_LOGIN_FAILED,
  TRANSLATION_LOGIN_SUCCESS,
  TRANSLATION_LOGIN_LANGUAGES_SUCCESS,
  TRANSLATION_LOGIN_DEFAULT_LANGUAGE_SUCCESS,
} from './types/TranslationLoginActionTypes';
import { callStatus } from './constants';

const initialState = {
  data: {},
  languages: [],
  errors: [],
  defaultLanguage: 'en-US',
  status: callStatus.PENDING,
};

const TranslationReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case TRANSLATION_LOGIN_PENDING:
      newState = {
        ...state,
        status: callStatus.PENDING,
        data: {},
        errors: [],
      };

      return newState;
    case TRANSLATION_LOGIN_IN_PROCESS:
      newState = {
        ...state,
        status: callStatus.IN_PROCESS,
        errors: [],
      };

      return newState;
    case TRANSLATION_LOGIN_SUCCESS:
      newState = {
        ...state,
        data: action.data,
        status: callStatus.SUCCESS,
        errors: [],
      };

      return newState;
    case TRANSLATION_LOGIN_FAILED:
      newState = {
        ...state,
        status: callStatus.FAILED,
        errors: action.data,
      };

      return newState;
    case TRANSLATION_LOGIN_LANGUAGES_SUCCESS:
      newState = {
        ...state,
        languages: action.data,
      };

      return newState;
    case TRANSLATION_LOGIN_DEFAULT_LANGUAGE_SUCCESS:
      newState = {
        ...state,
        defaultLanguage: action.data,
      };

      return newState;
    default:
      return state;
  }
};

export default TranslationReducer;
