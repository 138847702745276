import {
  TRANSLATION_PENDING,
  TRANSLATION_IN_PROCESS,
  TRANSLATION_FAILED,
  TRANSLATION_SUCCESS,
} from './types/TranslationActionTypes';

import { translationApi } from '../utils/api';

export const doGetTranslations = (dispatch, optional) => {
  dispatch(translationInProgress());

  return translationApi
    .get()
    .then((response) => {
      dispatch(translationSuccess(response.data['sl-main']));
    })
    .catch((error) => {
      console.log(error);
      dispatch(translationFailed(error));
    });
};

export const translationPending = () => {
  return {
    type: TRANSLATION_PENDING,
  };
};

export const translationInProgress = () => {
  return {
    type: TRANSLATION_IN_PROCESS,
  };
};

export const translationFailed = (data) => {
  return {
    type: TRANSLATION_FAILED,
    data,
  };
};

export const translationSuccess = (data) => {
  return {
    type: TRANSLATION_SUCCESS,
    data,
  };
};
