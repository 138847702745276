import React, { useEffect, useRef } from 'react';
import { Modal, Button, Input, Alert, Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { doChangePassword, loginPasswordPending } from '../redux/AuthActions';
import { callStatus } from '../redux/constants';
import { LockOutlined } from '@ant-design/icons';
import colors from '../constants/colors';
import { css } from '@emotion/css';

const ChangePassword = ({ closeModal }) => {
  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);
  const translations = useSelector(({ translations }) => translations);
  const context = useSelector(({ context }) => context);

  const { color } = context;

  const [form] = Form.useForm();

  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.input.focus();
    }
  }, [inputRef]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    form.validateFields().then((values) => {
      dispatch(
        doChangePassword({
          user: auth.data.user ? auth.data.user : auth.data.new_user,
          old_password: values.password,
          new_password: values.new_password,
        })
      );
    });
  };

  const handleCloseModal = () => {
    dispatch(loginPasswordPending());
    closeModal();
  };

  return (
    <Modal
      visible={true}
      onCancel={() => handleCloseModal()}
      mask={true}
      maskClosable={false}
      title={translations.data.password.title_modal}
      footer={[
        <Button
          key="close"
          onClick={() => handleCloseModal()}
          style={{ tabIndex: 1 }}
          className={css`
            &:hover {
              color: ${colors.header.more.input[color]};
              border: 1px solid ${colors.header.more.input[color]};
            }
          `}
        >
          {translations.data.password.close_button}
        </Button>,
        <Button
          key="submit"
          onClick={(e) => handleSubmit(e)}
          style={{ tabIndex: 2 }}
          className={css`
            &:hover {
              color: ${colors.header.more.input[color]};
              border: 1px solid ${colors.header.more.input[color]};
            }
          `}
        >
          {translations.data.password.change_button}
        </Button>,
      ]}
    >
      <Form form={form}>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: translations.data.password.message_old_password,
            },
          ]}
          hasFeedback
          style={{ marginBottom: '15px', height: '40px' }}
        >
          <Input.Password
            prefix={<LockOutlined className="input-icon" />}
            type="password"
            placeholder={translations.data.password.old_password}
            onPressEnter={handleSubmit}
            className={css`
              &.ant-input-affix-wrapper:hover {
                color: ${colors.header.more.input[color]};
                border: 1px solid ${colors.header.more.input[color]};
              }
            `}
            ref={inputRef}
          />
        </Form.Item>
        <Form.Item
          name="new_password"
          rules={[
            {
              required: true,
              message: translations.data.password.message_new_password,
            },
          ]}
          hasFeedback
          style={{ marginBottom: '15px', height: '40px' }}
        >
          <Input.Password
            prefix={<LockOutlined className="input-icon" />}
            type="password"
            placeholder={translations.data.password.new_password}
            onPressEnter={handleSubmit}
            className={css`
              &.ant-input-affix-wrapper:hover {
                color: ${colors.header.more.input[color]};
                border: 1px solid ${colors.header.more.input[color]};
              }
            `}
          />
        </Form.Item>

        <Form.Item
          name="confirm"
          dependencies={['new_password']}
          hasFeedback
          style={{ marginBottom: '15px', height: '40px' }}
          rules={[
            {
              required: true,
              message: translations.data.password.message_confirm_new_password,
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('new_password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(translations.data.password.message_equal_password)
                );
              },
            }),
          ]}
        >
          <Input.Password
            prefix={<LockOutlined className="input-icon" />}
            type="password"
            placeholder={translations.data.password.confirm_password}
            onPressEnter={handleSubmit}
            className={css`
              &.ant-input-affix-wrapper:hover {
                color: ${colors.header.more.input[color]};
                border: 1px solid ${colors.header.more.input[color]};
              }
            `}
          />
        </Form.Item>
      </Form>
      {auth.password.status === callStatus.FAILED && (
        <Alert message={auth.password.error} type="error" showIcon />
      )}
      {auth.password.status === callStatus.SUCCESS && (
        <Alert
          message={translations.data.password.message_change_success}
          type="success"
          showIcon
        />
      )}
    </Modal>
  );
};

export default ChangePassword;
