export const colors = {
  header: {
    icons: {
      blue: '#1e88e5',
      green: '#9CCC65',
      grey: '#607d8b',
      red: '#FF8A80',
      orange: '#FFC107',
    },
    nav: {
      menu: {
        blue: '#1e88e5',
        green: '#9CCC65',
        grey: '#607d8b',
        red: '#FF8A80',
        orange: '#FFC107',
        hover: {
          blue: '#1565C0',
          green: '#739b48', //this color is not in palette
          grey: '#455A64',
          red: '#FF5722',
          orange: '#ed9007',
        },
      },
      li: {
        blue: '#1e88e5',
        green: '#9CCC65',
        grey: '#607d8b',
        red: '#FF8A80',
        orange: '#FFC107',
      },
      blue: '#1e88e5',
      green: '#9CCC65',
      grey: '#607d8b',
      red: '#FF8A80',
      orange: '#FFC107',
    },

    more: {
      hover: {
        blue: '#1976d2',
        green: '#7CB342',
        grey: '#546e7a',
        red: '#F44336',
        orange: '#FF9800',
      },
      input: {
        blue: '#1565C0',
        green: '#DCEDC8',
        grey: '#455A64',
        red: '#FF5252',
        orange: '#FFD54F',
        border: {
          blue: 'white',
          green: '#7CB342',
          grey: '#607d8b',
          red: '#FFCDD2',
          orange: '#FFD54F',
        },
        hover: {
          blue: '#1e88e5',
          green: '#7CB342',
          grey: '#546e7a',
          red: '#F44336',
          orange: '#FF9800',
        },
      },
      blue: '#1976d2',
      green: '#7CB342',
      grey: '#546e7a',
      red: '#F44336',
      orange: '#FF9800',
    },
  },
};

export default colors;
