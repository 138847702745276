import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import {
  importCliTerminal,
  importCliHandler,
} from '../utils/dynamic-imports-sl-cli';
import { doAddConsoleLine, doExecCommand } from '../redux/cli/commandActions';
import { ALERT } from '../redux/cli/commandTypes';

const CliTerminal = importCliTerminal();
const useCliHandler = importCliHandler();

const About = ({ open, onCancel }) => {
  const dispatch = useDispatch();

  const translations = useSelector(({ translations }) => translations);
  const context = useSelector(({ context }) => context);

  const commandQueque = useRef({
    waiting: false,
    queque: [],
    optional: null,
  });

  const dispatchCommand = async (commands, optional) => {
    const command = commands.shift();

    if (commandQueque.current.waiting) {
      if (
        !commands.length &&
        !commandQueque.current.queque.find(
          (c) => c.commandText === command.commandText
        )
      ) {
        commandQueque.current.queque = [
          ...commandQueque.current.queque,
          command,
        ];
      }
    } else {
      if (command.type === ALERT) {
        commandQueque.current = {
          waiting: true,
          queque: cloneDeep(commands),
          optional,
        };
      } else {
        if (commandQueque.current.queque.length) {
          let newQueque = cloneDeep(commandQueque.current.queque);
          newQueque.shift();

          commandQueque.current.queque = newQueque;
        }
      }

      await doExecCommand(dispatch, command, optional).catch((error) => {
        setTimeout(() => {
          let message;
          if (error.type === 'unknown_command') {
            message = `${translations.data.cli.unrecognized_command} (Command: ${error.args})`;
          } else if (error.type === 'required_parameter') {
            message = `${translations.data.cli.parameter_required} (Parameter: ${error.args})`;
          } else {
            message = Array.isArray(error) ? error[0].message : error.message;
          }
          dispatch(doAddConsoleLine({ errors: message }));
          console.log(message);
        }, 500);
      });
    }

    if (commands?.length) {
      await dispatchCommand(commands, optional);
    }
  };

  useCliHandler(dispatchCommand);

  return (
    <CliTerminal
      title="About | Smartlis - SL-Main "
      modal={true}
      onCancel={onCancel}
      open={open}
      showTabJSON={false}
      width={750}
      height={500}
      consoleOutput={context.consoleOutput}
      onResize={(width, height) => {}}
    />
  );
};

export default About;
