class IdleTimer {
  constructor({ timeout, onTimeout, onExpired }) {
    this.timeout = timeout * 1000 * 60;
    this.onTimeout = onTimeout;

    const lastActivityTime = this.getLastActitivyTime();
    if (lastActivityTime > 0 && lastActivityTime + this.timeout < Date.now()) {
      onExpired();
      return;
    }

    this.eventHandler = this.updateLastActivityTime.bind(this);
    this.tracker();
    this.startInterval();
  }

  startInterval() {
    this.updateLastActivityTime();

    this.interval = setInterval(() => {
      const lastActivityTime = this.getLastActitivyTime();
      if (lastActivityTime + this.timeout < Date.now()) {
        if (this.onTimeout) {
          this.onTimeout();
        }
      }
    }, 1000);
  }

  updateLastActivityTime() {
    if (this.activityTracker) {
      clearTimeout(this.activityTracker);
    }
    this.activityTracker = setTimeout(() => {
      localStorage.setItem('_lastActivityTime', Date.now());
    }, 300);
  }

  tracker() {
    window.addEventListener('mousemove', this.eventHandler);
    window.addEventListener('scroll', this.eventHandler);
    window.addEventListener('keydown', this.eventHandler);
  }

  cleanUp() {
    localStorage.removeItem('_lastActivityTime');
    clearTimeout(this.activityTracker);
    clearInterval(this.interval);
    window.removeEventListener('mousemove', this.eventHandler);
    window.removeEventListener('scroll', this.eventHandler);
    window.removeEventListener('keydown', this.eventHandler);
  }

  getLastActitivyTime() {
    return parseInt(localStorage.getItem('_lastActivityTime'), 10);
  }
}

export default IdleTimer;
