export const MAIN = {
  IDLE: 'sl-main/main/MAIN/idle',
  IN_PROGRESS: 'sl-main/main/MAIN/pending',
  REJECTED: 'sl-main/main/MAIN/rejected',
  RESOLVED: 'sl-main/main/MAIN/resolved',
};

export const IFRAME = {
  SET_INIT: 'sl-main/main/IFRAME/set_init',
  SHOW: 'sl-main/main/IFRAME/show',
  REFRESH: 'sl-main/main/IFRAME/refresh',
  DUPLICATE: 'sl-main/main/IFRAME/duplicate',
  OPEN_TAB: 'sl-main/main/IFRAME/open_tab',
};

export const MODULE = {
  SET: 'sl-main/main/IFRAME/set',
};

export const COLOR = {
  SET: 'sl-main/main/COLOR/set',
};
