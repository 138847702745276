import {
  TRANSLATION_LOGIN_DEFAULT_LANGUAGE_SUCCESS,
  TRANSLATION_LOGIN_FAILED,
  TRANSLATION_LOGIN_IN_PROCESS,
  TRANSLATION_LOGIN_LANGUAGES_SUCCESS,
  TRANSLATION_LOGIN_PENDING,
  TRANSLATION_LOGIN_SUCCESS,
} from './types/TranslationLoginActionTypes';

import { genericApi, systemApi, translationApi } from '../utils/api';
import { translationSuccess } from './TranslationAction';

export const doGetLoginTranslations = (dispatch, optional) => {
  dispatch(translationLoginInProgress());

  genericApi
    .get('/Language/get_objects/', {
      params: { post: { work_sess_user: '*auto*' } },
    })
    .then((response) => {
      dispatch(setTranslationLoginLanguages(response.data.rows));

      systemApi
        .get('/get_settings_vars/LANGUAGE_CODE', {
          params: { post: { work_sess_user: '*auto*' } },
        })
        .then((response) => {
          let languageCode = 'en-US';
          if (response?.data?.LANGUAGE_CODE) {
            languageCode = response.data.LANGUAGE_CODE;
          }

          translationApi
            .post(null, { lang: null })
            .then((response) => {
              dispatch(translationLoginSuccess(response.data));

              if (
                response.data[languageCode] &&
                response.data[languageCode]['sl-main']
              ) {
                dispatch(setTranslationLoginDefaultLanguage(languageCode));
                dispatch(
                  translationSuccess(response.data[languageCode]['sl-main'])
                );
              } else {
                const [key, lang] = Object.entries(response.data).find(
                  ([key, val]) => val && val['sl-main']
                );
                if (lang) {
                  dispatch(setTranslationLoginDefaultLanguage(key));
                  dispatch(translationSuccess(lang['sl-main']));
                }
              }
            })
            .catch((error) => {
              console.log(error);

              if (error?.code === 'TR01') {
                translationApi
                  .get()
                  .then((response) => {
                    dispatch(setTranslationLoginDefaultLanguage(languageCode));
                    dispatch(
                      translationLoginSuccess({ [languageCode]: response.data })
                    );
                    dispatch(translationSuccess(response.data['sl-main']));
                  })
                  .catch((error) => {
                    console.log(error);
                    dispatch(translationLoginFailed(error));
                  });
              } else {
                dispatch(translationLoginFailed(error));
              }
            });
        })
        .catch((error) => {
          console.log(error);
          dispatch(translationLoginFailed(error));
        });
    })
    .catch((error) => {
      dispatch(translationLoginFailed(error));
    });
};

export const doChangeLoginLanguage = (code) => (dispatch, getState) => {
  dispatch(setTranslationLoginDefaultLanguage(code));
  dispatch(
    translationSuccess(getState().translationsLogin.data[code]['sl-main'])
  );
};

export const translationLoginPending = () => {
  return {
    type: TRANSLATION_LOGIN_PENDING,
  };
};

export const translationLoginInProgress = () => {
  return {
    type: TRANSLATION_LOGIN_IN_PROCESS,
  };
};

export const translationLoginFailed = (data) => {
  return {
    type: TRANSLATION_LOGIN_FAILED,
    data,
  };
};

export const translationLoginSuccess = (data) => {
  return {
    type: TRANSLATION_LOGIN_SUCCESS,
    data,
  };
};

export const setTranslationLoginLanguages = (data) => {
  return {
    type: TRANSLATION_LOGIN_LANGUAGES_SUCCESS,
    data,
  };
};

export const setTranslationLoginDefaultLanguage = (data) => {
  return {
    type: TRANSLATION_LOGIN_DEFAULT_LANGUAGE_SUCCESS,
    data,
  };
};
