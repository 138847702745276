import React from 'react';

const TopBanner = ({ data }) => {
  return (
    <div
      style={{
        width: '100%',
        background: data.bg_color ? data.bg_color : 'white',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      {data.left_image ? (
        <div>
          <img src={data.left_image} alt="left_image" />
        </div>
      ) : (
        <span></span>
      )}
      {data.right_image ? (
        <div>
          <img src={data.right_image} alt="right_image"></img>
        </div>
      ) : (
        <span></span>
      )}
    </div>
  );
};

export default TopBanner;
